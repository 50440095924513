function add0(m){return m<10?'0'+m:m }

let tool = {
	// 格式化日期时间
    dateFormat: function(d) {
        let fmt= d.getFullYear()  +'-'
                 + (d.getMonth() + 1) + '-'
                 + d.getDate() +' '
                 + d.getHours() + ':'
                 + d.getMinutes() + ':'
                 + d.getSeconds()
        return fmt
    },
	// 格式化日期
    dateFormatD: function(d) {
        let numMth = (d.getMonth() + 1);
        let numDay = d.getDate();
        let mth = numMth>=10 ? numMth : ('0'+numMth);
        let day = numDay>=10 ? numDay : ('0'+numDay);        
        return(d.getFullYear()  +'-' + mth + '-' + day);
    },
    
    // 时间戳转成日期格式
    formatTs : function(ts)
    {
        var time = new Date(ts);    //ts是整数，否则要parseInt转换
        var y = time.getFullYear();
        var m = time.getMonth()+1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
    },
    
    // 日期年月
    dateYM : function(d) {
        let numMth = (d.getMonth() + 1);
        let mth = numMth>=10 ? (''+numMth) : ('0'+numMth);
        return(d.getFullYear() + mth);
    },
    
    // 日期的月
    dateMonth : function(d) {
        return (d.getMonth() + 1);
    },
    
    // 返回字符串中包含指定字符的数量
    charnumInsideString : function(str, char) {
        let n = 0;
        for (let i=0; i<str.length; i++) {
            if (str.charAt(i) == char) {
                n++;
            }
        }
        return n;
    },
}

export default tool;
